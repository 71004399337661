import BrandSVG from "images/brand.svg";
import LogoSVG from "images/logo.svg";
import Link from "next/link";
import React from "react";

import Styles from "./Logo.styles";
import { LogoProps as Props } from "./Logo.types";
import CONSTANTS from "config/constants";

const { TITLE } = CONSTANTS;

const Logo: React.FC<Props> = props => {
  const { className, onClick, withoutLink = false } = props;
  const label = `${TITLE} logo`;
  const logoNode = (
    <div className="Logo__desktop">
      <LogoSVG viewBox="0 0 237 55" />
    </div>
  );
  const brandNode = (
    <div className="Logo__no-desktop">
      <BrandSVG viewBox="0 0 55 55" />
    </div>
  );

  if (withoutLink) {
    return <Styles className={`Logo ${className}`}>{logoNode}</Styles>;
  }

  return (
    <Styles className={`Logo ${className}`}>
      {onClick ? (
        <div onClick={onClick} aria-label={label}>
          {logoNode} {brandNode}
        </div>
      ) : (
        <Link href="/billboard" passHref aria-label={label}>
          <div aria-label={label}>
            {logoNode}
            {brandNode}
          </div>
        </Link>
      )}
    </Styles>
  );
};

Logo.defaultProps = {
  className: ""
};

export default Logo;
