import { TabsMenu as TabsMenuUI } from "artisn-ui-react";
import SpotlightSVG from "images/TabsMenu/promo.svg";
import { useRouter } from "next/router";
import React from "react";

import Styles from "./SpotlightTabsMenuItem.styles";
import { SpotlightTabsMenuItemProps as Props } from "./SpotlightTabsMenuItem.types";
import useI18n from "hooks/useI18n";
import { useGlobalsStore } from "stores/globals/globals.store";

const { Item } = TabsMenuUI;

const SpotlightTabsMenuItem: React.FC<Props> = props => {
  const { className, hideTitle, active } = props;
  const { push } = useRouter();
  const t = useI18n();
  const setRedirectedBillboard = useGlobalsStore(
    state => state.setRedirectedBillboard
  );

  const onClickHandler = async () => {
    setRedirectedBillboard(true);
    await push("/");
  };

  return (
    <Styles className={`SpotlightTabsMenuItem ${className}`} active={active}>
      <Item
        icon={SpotlightSVG}
        title={hideTitle ? undefined : t.navigation.spotlight}
        onClick={onClickHandler}
        className="SpotlightTabsMenuItem__item"
      />
    </Styles>
  );
};

SpotlightTabsMenuItem.defaultProps = {
  className: ""
};

export default SpotlightTabsMenuItem;
