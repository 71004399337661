import { CINEMARK_TICKETS_VENDOR } from "@multicines/utils";
import PlaySVG from "images/play.svg";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";

import Styles from "./Navbar.styles";
import { NavbarProps as Props } from "./Navbar.types";
import useI18n from "../../../hooks/useI18n";
import ActiveTicket from "../ActiveTicket/ActiveTicket";
import Button from "../Button/Button";
import GoBack from "../GoBack/GoBack";
import Logo from "../Logo/Logo";
import SearchBarProducts from "../SearchBarProducts/SearchBarProducts";
import SelectAddressDropdown from "../SelectAddressDropdown/SelectAddressDropdown";
import CartButton from "../navigation/CartButton/CartButton";
import CouponsTabsMenuItem from "../navigation/CouponsTabsMenuItem/CouponsTabsMenuItem";
import IceTabsMenuItem from "../navigation/IceTabsMenuItem/IceTabsMenuItem";
import MoviesTabsMenuItem from "../navigation/MoviesTabsMenuItem/MoviesTabsMenuItem";
import MulticlubTabsMenuItem from "../navigation/MulticlubTabsMenuItem/MulticlubTabsMenuItem";
import SnacksTabsMenuItem from "../navigation/SnacksTabsMenuItem/SnacksTabsMenuItem";
import SpotlightTabsMenuItem from "../navigation/SpotlightTabsMenuItem/SpotlightTabsMenuItem";
import CinemaAndDay from "components/CinemaAndDay/CinemaAndDay";
import CONSTANTS from "config/constants";
import useAnalytics from "hooks/analytics/useAnalytics";
import useTalkShop from "hooks/useTalkShop";
import { useAuthStore } from "stores/auth/auth.store";
import { useStoresStore } from "stores/stores/stores.store";

const { WITH_STORE_COUPONS } = CONSTANTS.FEATURE_FLAGS;
const { WITH_SELECT_ADDRESS_DROPDOWN } = CONSTANTS.FEATURE_FLAGS;
const { WITH_PURCHASE, WITH_MULTICINES_PLAY } = CONSTANTS.FEATURE_FLAGS;
const { playURL } = CONSTANTS.EXTERNALS_URLS;

const Navbar: React.FC<Props> = props => {
  const { className, showBrand = false, title, icon, product, to } = props;
  const { showBottomSearch = true, showCart = true, shareIcon } = props;
  const { pathname } = useRouter();
  const isAnonymous = useAuthStore(state => state.isAnonymous);
  const { isTalkShop, navigateWithTalkShop } = useTalkShop();
  const analyticsHook = useAnalytics({
    isTalkShop
  });
  const [active, setActive] = useState(-1);
  const notHome = !showBrand ? "not-home" : "";
  const selectedStore = useStoresStore(store => store.selectedStore);
  const [ticketsStore] = selectedStore ?? [];
  const { vendor } = ticketsStore ?? {};
  const isShowCart = !showCart ? "Navbar__btn--hidden" : null;
  const t = useI18n();

  const { logSelectLogin } = analyticsHook;

  const { id: selectedTicketId } = vendor ?? {};
  const isCinemak = selectedTicketId === CINEMARK_TICKETS_VENDOR.id;

  const renderCartButton = () => {
    if (!WITH_PURCHASE) return null;
    return (
      <CartButton
        className={`Navbar__btn Navbar__btn--cart ${isShowCart}`}
        product={product}
      />
    );
  };

  const iconNode = (
    <div className="Navbar__icon">
      {renderCartButton()}
      {shareIcon}
    </div>
  );

  const loginButtonHandler = () => {
    logSelectLogin();
    navigateWithTalkShop("/signup");
  };

  const clickHandler = () => {
    window.open(playURL, "__blank");
  };

  useEffect(() => {
    if (pathname === "/") {
      setActive(0);
    } else if (pathname.startsWith("/billboard")) {
      setActive(1);
    } else if (pathname.startsWith("/categories")) {
      setActive(2);
    } else if (pathname.startsWith("/profile/membership")) {
      setActive(3);
    } else if (
      pathname.startsWith("/profile") ||
      pathname.startsWith("/profile/tickets")
    ) {
      setActive(4);
    }
  }, [pathname]);

  return (
    <Styles
      className={`Navbar ${className}`}
      showBottomSearch={showBottomSearch}
      talkShop={isTalkShop}
      id="navbar"
      isAnonymous={isAnonymous}
    >
      <main className="Navbar__content">
        <div className={`Navbar__top Navbar__top__responsive ${notHome}`}>
          <GoBack to={to} />
          {title || title === "" ? (
            <p className="Navbar__title">{title}</p>
          ) : (
            <SearchBarProducts className="Navbar__search-bar Navbar__search-bar__center" />
          )}
          {icon ?? iconNode}
        </div>
        <div className={`Navbar__top Navbar__top__brand ${notHome}`}>
          <div className="Navbar__top Navbar__top__container">
            <div className="Navbar__no-desktop">
              {isTalkShop ? (
                <Logo className="Navbar__logo" onClick={() => {}} />
              ) : (
                <Logo className="Navbar__logo" />
              )}
              <div className="Navbar__cinema">
                <CinemaAndDay />
              </div>
            </div>
            <CartButton
              className="Navbar__btn Navbar__btn__cart Navbar__cart__desktop"
              product={product}
            />
          </div>
          <div className="Navbar__top Navbar__top__buttons">
            {isAnonymous ? (
              <Button
                mode="PRIMARY"
                className="Navbar__btn Navbar__btn__login"
                onClick={loginButtonHandler}
              >
                {t.navigation.login}
              </Button>
            ) : null}
            <IceTabsMenuItem
              className="Navbar__btn Navbar__btn__movies"
              active={false}
            />
            {WITH_MULTICINES_PLAY ? (
              <Button
                className="Navbar__btn Navbar__btn__play"
                isLoading={false}
                onClick={clickHandler}
              >
                <PlaySVG /> <span>Multicines Play</span>
              </Button>
            ) : null}
            <MoviesTabsMenuItem
              hideTitle={false}
              className="Navbar__btn Navbar__btn__movies"
              active={active === 1}
            />
            <SnacksTabsMenuItem
              hideTitle={false}
              className="Navbar__btn Navbar__btn__snacks"
              active={active === 2}
            />
            {!isCinemak ? (
              <MulticlubTabsMenuItem
                hideTitle={false}
                className="Navbar__btn Navbar__btn__multiclub"
                active={active === 3}
              />
            ) : null}
            <SpotlightTabsMenuItem
              hideTitle={false}
              className="Navbar__btn Navbar__btn__movies"
              active={active === 0}
            />
            ,
            <ActiveTicket
              className="Navbar__btn Navbar__btn__profile"
              {...props}
              active={active === 4}
            />
            <div className="Navbar__divider" />
            <CartButton
              className="Navbar__btn Navbar__btn__cart Navbar__cart__mobile"
              product={product}
            />
          </div>

          {WITH_SELECT_ADDRESS_DROPDOWN ? (
            <SelectAddressDropdown className="Navbar__dropdown" />
          ) : null}
          {/* TODO: enable when favs component is ready
          {WITH_LOYALTY ? (
            <LoyaltyTabsMenuItem
              hideTitle
              className="Navbar__btn Navbar__btn__loyalty"
              active={false}
            />
          ) : null} */}
          {WITH_STORE_COUPONS ? (
            <CouponsTabsMenuItem
              hideTitle
              className="Navbar__btn Navbar__btn__coupons"
              active={false}
            />
          ) : null}
        </div>
      </main>
    </Styles>
  );
};

Navbar.defaultProps = {
  className: ""
};

export default Navbar;
